import React from "react";
import "./Contact.css";
import { FaPhone, FaInstagram } from "react-icons/fa";
import { Contactphoto } from "../Assets/images";

const Contact = () => {
  return (
    <div class="container">
      <div class="form">
        <div class="contact-form">
          <form action="https://formspree.io/f/mleykzwd" method="POST">
            <h3 class="title">Mandami una mail</h3>
            <div class="input-container">
              <input
                type="text"
                name="name"
                class="input"
                placeholder="Nome*"
                required
              />
            </div>
            <div class="input-container">
              <input
                type="text"
                name="surname"
                class="input"
                placeholder="Cognome*"
                required
              />
            </div>
            <div class="input-container">
              <input
                type="email"
                name="email"
                class="input"
                placeholder=" La tua Email*"
              />
            </div>
            <div class="input-container textarea">
              <textarea
                name="message"
                class="input"
                placeholder="Messaggio.."
                required
              ></textarea>
            </div>
            <input type="submit" value="Invia" class="btn" />
          </form>

          <div class="contact-info">
            <div class="social-media">
              <p>Seguimi su instagram: </p>
              <div class="social-icons">
                <a href="https://www.instagram.com/__b.erika__/">
                  <FaInstagram id="icona" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-image">
        <img src={Contactphoto} alt="portrait" />
      </div>
    </div>
  );
};
export default Contact;
