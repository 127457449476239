import React from "react";
import Navbar from "../Navbar";
import PhotoGridMaternity from "../PhotoGridMaternity";

function Maternity() {
  document.body.classList.remove("no-scroll");
  return (
    <div>
      <Navbar />
      <PhotoGridMaternity />
    </div>
  );
}

export default Maternity;
