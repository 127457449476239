import React from "react";
import "./BioComp.css";
import { erikaBio } from "../Assets/images";

const BioComp = () => {
  return (
    <div className="bio">
      <div className="about-image">
        <img src={erikaBio} alt="portrait" />
      </div>
      <div className="about-text">
        <div className="about-life">
          <h3>Chi sono</h3>
          <p>
            Mi chiamo Erika e sono una fotografa in provincia di Treviso. Ho
            un'esperienza decennale in ambito fotografico. Ciò che penso sia
            imprescindibile da uno scatto è l'emozionalità della foto. La
            bellezza dell'essere umano può e deve essere catturata dal
            fotografo, nel momento magico in cui essa si manifesta. Le emozioni
            sono ciò che danno colore e intensità alle nostre vite e la mia
            mission è catturare quei momenti unici e irripetibili e di
            valorizzare la singolarità dell'essere umano.
          </p>
        </div>
      </div>
    </div>
  );
};
export default BioComp;
