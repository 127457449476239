import React from "react";
import "./PhotoGrid.css";
import "./style/style.css";
import { plant1, plant2, profilepic } from "../Assets/images";

function PhotoGrid() {
  return (
    <div className="styled">
      <div className="hero-introduction flex">
        <h2>
          Erika Bruni
          <br />
          Photographer
        </h2>
        <p>
          "C'è una cosa che la fotografia deve avere, l'umanità del momento"{" "}
          <br></br>
          <cite>Robert Frank</cite>
        </p>
        <a href="/Ritratto">Galleria</a>
      </div>
      <div className="hero-images">
        <img className="hero-erika" src={profilepic} alt="elena joy" />
      </div>
    </div>
  );
}

export default PhotoGrid;
