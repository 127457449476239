import React from "react";
import Navbar from "../Navbar";
import PhotoGridNatura from "../PhotoGridNatura";

function Natura() {
  document.body.classList.remove("no-scroll");
  return (
    <div>
      <Navbar />
      <PhotoGridNatura />
    </div>
  );
}

export default Natura;
