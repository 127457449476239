import React, { useState } from "react";
import "./style/style.css";
import "./PhotoGridNatura.css";
import {
  Landscape1,
  Landscape2,
  Landscape3,
  Landscape4,
  Landscape5,
  Landscape6,
  Landscape7,
  Landscape8,
  Landscape9,
} from "../Assets/images";

function PhotoGridNatura() {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="photo-container-landscape">
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="fullscreen-image"
          />
        </div>
      )}

      <div className="photo-gallery">
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Landscape1)}>
            <img
              src={Landscape1}
              className="image-landscape"
              alt="Landscape1"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Landscape2)}>
            <img
              src={Landscape2}
              className="image-landscape"
              alt="Landscape2"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Landscape7)}>
            <img
              src={Landscape7}
              className="image-landscape"
              alt="Landscape7"
            ></img>
          </div>
        </div>
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Landscape4)}>
            <img
              src={Landscape4}
              className="image-landscape"
              alt="Landscape4"
            ></img>
          </div>

          <div className="photo" onClick={() => openFullscreen(Landscape5)}>
            <img
              src={Landscape5}
              className="image-landscape"
              alt="Landscape5"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Landscape8)}>
            <img
              src={Landscape8}
              className="image-landscape"
              alt="Landscape8"
            ></img>
          </div>
        </div>

        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Landscape3)}>
            <img
              src={Landscape3}
              className="image-landscape"
              alt="Landscape3"
            ></img>
          </div>

          <div className="photo" onClick={() => openFullscreen(Landscape9)}>
            <img
              src={Landscape9}
              className="image-landscape"
              alt="Landscape9"
            ></img>
          </div>

          <div className="photo" onClick={() => openFullscreen(Landscape6)}>
            <img
              src={Landscape6}
              className="image-landscape"
              alt="Landscape6"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGridNatura;
