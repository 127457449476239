import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import BioComp from "../BioComp";
import Created from "../created";

function Bio() {
  return (
    <div>
      <Navbar />
      <BioComp />
      <Created />
    </div>
  );
}

export default Bio;
