import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Contact from "../Contact";
import Created from "../created";

function Contatti() {
  return (
    <div>
      <Navbar />
      <Contact />
      <Created />
    </div>
  );
}

export default Contatti;
