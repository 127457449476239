import React from "react";
import "./created.css";

function Created() {
  return (
    <div className="createdby">
      <p>
        Created by{" "}
        <a href="https://www.linkedin.com/in/stefaniatagliavini-0611n/">
          Stefania Tagliavini
        </a>{" "}
      </p>
    </div>
  );
}

export default Created;
