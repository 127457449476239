export const Landscape1 = require("../Assets/Landscape/Landscape1.jpg");
export const Landscape2 = require("../Assets/Landscape/Landscape2.jpg");
export const Landscape3 = require("../Assets/Landscape/Landscape3.jpg");
export const Landscape4 = require("../Assets/Landscape/Landscape4.jpg");
export const Landscape5 = require("../Assets/Landscape/Landscape5.jpg");
export const Landscape6 = require("../Assets/Landscape/Landscape6.jpg");
export const Landscape7 = require("../Assets/Landscape/Landscape7.jpg");
export const Landscape8 = require("../Assets/Landscape/Landscape8.jpg");
export const Landscape9 = require("../Assets/Landscape/Landscape9.jpg");

export const Maternity1 = require("../Assets/Maternity/Maternity1.webp");
export const Maternity2 = require("../Assets/Maternity/Maternity2.webp");
export const Maternity3 = require("../Assets/Maternity/Maternity3.webp");
export const Maternity4 = require("../Assets/Maternity/Maternity4.jpg");
export const Maternity5 = require("../Assets/Maternity/Maternity5.jpg");
export const Maternity6 = require("../Assets/Maternity/Maternity6.jpg");
export const Maternity7 = require("../Assets/Maternity/Maternity7.jpg");
export const Maternity8 = require("../Assets/Maternity/Maternity8.jpg");

export const Ritratto1 = require("../Assets/Ritratto/Ritratto1.jpg");
export const Ritratto2 = require("../Assets/Ritratto/Ritratto2.jpg");
export const Ritratto3 = require("../Assets/Ritratto/Ritratto3.jpg");
export const Ritratto4 = require("../Assets/Ritratto/Ritratto4.jpg");
export const Ritratto5 = require("../Assets/Ritratto/Ritratto5.jpg");
export const Ritratto6 = require("../Assets/Ritratto/Ritratto6.jpg");
export const Ritratto7 = require("../Assets/Ritratto/Ritratto7.jpg");
export const Ritratto8 = require("../Assets/Ritratto/Ritratto8.jpg");
export const Ritratto9 = require("../Assets/Ritratto/Ritratto9.jpg");
export const Ritratto10 = require("../Assets/Ritratto/Ritratto10.jpg");
export const Ritratto11 = require("../Assets/Ritratto/Ritratto11.jpg");
export const Ritratto12 = require("../Assets/Ritratto/Ritratto12.jpg");

export const RitrattoCoppia1 = require("../Assets/RitrattoCoppia/RitrattoCoppia1.jpg");
export const RitrattoCoppia2 = require("../Assets/RitrattoCoppia/RitrattoCoppia2.jpg");
export const RitrattoCoppia3 = require("../Assets/RitrattoCoppia/RitrattoCoppia3.jpg");
export const RitrattoCoppia4 = require("../Assets/RitrattoCoppia/RitrattoCoppia4.jpg");
export const RitrattoCoppia5 = require("../Assets/RitrattoCoppia/RitrattoCoppia5.jpg");
export const RitrattoCoppia6 = require("../Assets/RitrattoCoppia/RitrattoCoppia6.jpg");
export const RitrattoCoppia7 = require("../Assets/RitrattoCoppia/RitrattoCoppia7.jpg");
export const RitrattoCoppia8 = require("../Assets/RitrattoCoppia/RitrattoCoppia8.jpg");
export const RitrattoCoppia9 = require("../Assets/RitrattoCoppia/RitrattoCoppia9.jpg");
export const RitrattoCoppia10 = require("../Assets/RitrattoCoppia/RitrattoCoppia10.jpg");

export const Contactphoto = require("../Assets/contact-photo.jpg");
export const plant1 = require("../Assets/Plant1.png");
export const plant2 = require("../Assets/Plant2.png");
export const profilepic = require("../Assets/erika-profilo.png");
export const elena = require("../Assets/download.png");
export const erikaBio = require("../Assets/erika-bio.jpg");
