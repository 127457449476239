import React from "react";
import Navbar from "../Navbar";
import PhotoGridRitratto from "../PhotoGridRitratto";

function Ritratto() {
  document.body.classList.remove("no-scroll");
  return (
    <div>
      <Navbar />
      <PhotoGridRitratto />
    </div>
  );
}

export default Ritratto;
