import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bio from "./Components/pages/Bio";
import Home from "./Components/pages/Home";
import Contatti from "./Components/pages/Contatti";
import Natura from "./Components/pages/Natura";
import Ritratto from "./Components/pages/Ritratto";
import Maternity from "./Components/pages/Maternity";
import RitrattoCoppia from "./Components/pages/RitrattoCoppia";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Bio" element={<Bio />} />
          <Route path="/Contatti" element={<Contatti />} />
          <Route path="/Natura" element={<Natura />} />
          <Route path="/Ritratto" element={<Ritratto />} />
          <Route path="/Maternity" element={<Maternity />} />
          <Route path="/RitrattoCoppia" element={<RitrattoCoppia />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
