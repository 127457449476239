import React from "react";
import Navbar from "../Navbar";
import PhotoGridRitrattoCoppia from "../PhotoGridRitrattoCoppia";

function RitrattoCoppia() {
  document.body.classList.remove("no-scroll");
  return (
    <div>
      <Navbar />
      <PhotoGridRitrattoCoppia />
    </div>
  );
}

export default RitrattoCoppia;
