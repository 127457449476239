import React, { useState } from "react";
import "./style/style.css";
import "./PhotoGridRitratto.css";
import {
  Ritratto1,
  Ritratto2,
  Ritratto3,
  Ritratto4,
  Ritratto5,
  Ritratto6,
  Ritratto7,
  Ritratto8,
  Ritratto9,
  Ritratto10,
  Ritratto11,
  Ritratto12,
} from "../Assets/images";

function PhotoGridRitratto() {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="photo-container-ritratto">
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="fullscreen-image"
          />
        </div>
      )}

      <div className="photo-gallery">
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Ritratto1)}>
            <img
              src={Ritratto1}
              className="image-ritratto"
              alt="Ritratto1"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto2)}>
            <img
              src={Ritratto2}
              className="image-ritratto"
              alt="Ritratto2"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto3)}>
            <img
              src={Ritratto3}
              className="image-ritratto"
              alt="Ritratto3"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto12)}>
            <img
              src={Ritratto12}
              className="image-ritratto"
              alt="Ritratto12"
            ></img>
          </div>
        </div>

        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Ritratto4)}>
            <img
              src={Ritratto4}
              className="image-ritratto"
              alt="Ritratto4"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto5)}>
            <img
              src={Ritratto5}
              className="image-ritratto"
              alt="Ritratto5"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto6)}>
            <img
              src={Ritratto6}
              className="image-ritratto"
              alt="Ritratto6"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto11)}>
            <img
              src={Ritratto11}
              className="image-ritratto"
              alt="Ritratto11"
            ></img>
          </div>
        </div>
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Ritratto7)}>
            <img
              src={Ritratto7}
              className="image-ritratto"
              alt="Ritratto7"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto8)}>
            <img
              src={Ritratto8}
              className="image-ritratto"
              alt="Ritratto8"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto9)}>
            <img
              src={Ritratto9}
              className="image-ritratto"
              alt="Ritratto9"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Ritratto10)}>
            <img
              src={Ritratto10}
              className="image-ritratto"
              alt="Ritratto10"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGridRitratto;
