export const MenuItems = [
  {
    title: "Natura",
    path: "/Natura",
    cName: "dropdown-link",
  },
  {
    title: "Ritratti",
    path: "/Ritratto",
    cName: "dropdown-link",
  },
  {
    title: "Maternità",
    path: "/Maternity",
    cName: "dropdown-link",
  },
  {
    title: "Ritratti di coppia",
    path: "/RitrattoCoppia",
    cName: "dropdown-link",
  },
];
