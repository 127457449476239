import React, { useState } from "react";
import "./style/style.css";
import "./PhotoGridMaternity.css";
import {
  Maternity1,
  Maternity2,
  Maternity3,
  Maternity4,
  Maternity5,
  Maternity6,
  Maternity7,
  Maternity8,
} from "../Assets/images";

function PhotoGridMaternity() {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="photo-container-maternity">
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="fullscreen-image"
          />
        </div>
      )}

      <div className="photo-gallery">
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Maternity1)}>
            <img
              src={Maternity1}
              className="image-maternity"
              alt="Maternity1"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Maternity2)}>
            <img
              src={Maternity2}
              className="image-maternity"
              alt="Maternity2"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Maternity3)}>
            <img
              src={Maternity3}
              className="image-maternity"
              alt="Maternity3"
            ></img>
          </div>
        </div>
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Maternity4)}>
            <img
              src={Maternity4}
              className="image-maternity"
              alt="Maternity4"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Maternity5)}>
            <img
              src={Maternity5}
              className="image-maternity"
              alt="Maternity5"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Maternity6)}>
            <img
              src={Maternity6}
              className="image-maternity"
              alt="Maternity6"
            ></img>
          </div>
        </div>
        <div className="column">
          <div className="photo" onClick={() => openFullscreen(Maternity7)}>
            <img
              src={Maternity7}
              className="image-maternity"
              alt="Maternity7"
            ></img>
          </div>
          <div className="photo" onClick={() => openFullscreen(Maternity8)}>
            <img
              src={Maternity8}
              className="image-maternity"
              alt="Maternity8"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGridMaternity;
