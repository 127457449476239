import { useRef, useState, useEffect } from "react";
import { FaBars, FaCaretDown, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import "./style/style.css";
import Dropdown from "./Dropdown";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

function Navbar() {
  const navResponsive = useRef();

  const showNavbar = () => {
    navResponsive.current.classList.toggle("responsive_nav");
  };

  const hideNavbar = () => {
    navResponsive.current.classList.remove("responsive_nav");
  };

  //dropdown onclick

  const [isShown, setIsShown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };

  // Add a click event listener to the document
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setIsShown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <navbar className="sticky">
      <Link className="logo" to="/">
        Erika Bruni
      </Link>
      <nav ref={navResponsive}>
        <ul>
          <li>
            <Link to="/" onClick={hideNavbar}>
              Home
            </Link>
          </li>

          <li onClick={handleClick} ref={dropdownRef}>
            <Link to="">
              Galleria <FaCaretDown id="freccia" />
            </Link>
            {isShown && <Dropdown hideNavbar={hideNavbar} />}
          </li>

          <li>
            <Link to="/Bio" onClick={hideNavbar}>
              Bio
            </Link>
          </li>
          <li>
            <Link to="/Contatti" onClick={hideNavbar}>
              Contatti
            </Link>
          </li>
        </ul>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
      <div class="social-icons-home">
        <a href="https://www.instagram.com/__b.erika__/">
          <FaInstagram id="icona" />
        </a>
      </div>
    </navbar>
  );
}

export default Navbar;
