import React, { useState } from "react";
import "./PhotoGridRitrattoCoppia.css";
import {
  RitrattoCoppia1,
  RitrattoCoppia2,
  RitrattoCoppia3,
  RitrattoCoppia4,
  RitrattoCoppia5,
  RitrattoCoppia6,
  RitrattoCoppia7,
  RitrattoCoppia8,
  RitrattoCoppia9,
  RitrattoCoppia10,
} from "../Assets/images";

function PhotoGridRitrattoCoppia() {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="photo-container-ritrattocoppia">
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="fullscreen-image"
          />
        </div>
      )}

      <div className="photo-gallery">
        <div className="column">
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia1)}
          >
            <img
              src={RitrattoCoppia1}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia1"
            ></img>
          </div>
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia2)}
          >
            <img
              src={RitrattoCoppia2}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia2"
            ></img>
          </div>
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia3)}
          >
            <img
              src={RitrattoCoppia3}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia3"
            ></img>
          </div>
        </div>
        <div className="column">
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia4)}
          >
            <img
              src={RitrattoCoppia4}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia4"
            ></img>
          </div>

          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia5)}
          >
            <img
              src={RitrattoCoppia5}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia5"
            ></img>
          </div>
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia6)}
          >
            <img
              src={RitrattoCoppia6}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia6"
            ></img>
          </div>
        </div>

        <div className="column">
          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia7)}
          >
            <img
              src={RitrattoCoppia7}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia7"
            ></img>
          </div>

          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia8)}
          >
            <img
              src={RitrattoCoppia8}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia8"
            ></img>
          </div>

          <div
            className="photo"
            onClick={() => openFullscreen(RitrattoCoppia9)}
          >
            <img
              src={RitrattoCoppia9}
              className="image-ritrattocoppia"
              alt="RitrattoCoppia9"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoGridRitrattoCoppia;
